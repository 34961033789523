<template>
  <div>
    <Banner></Banner>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>产品优势</p>
          <p>PRODUCT ADVANTAGE</p>
        </div>
        <div class="hxjs-body">
          <div class="advantage_msg">
            <img src="~@/assets/imgs/diting/adv_01.png" alt="">
            <p class="adv_title"><span>准确性更高</span></p>
            <p class="adv_det"><span>大模型有更多的参数和更大的训练数据集，提高预测的准确性。</span></p>
          </div>
          <div class="advantage_msg">
            <img src="~@/assets/imgs/diting/adv_02.png" alt="">
            <p class="adv_title"><span>泛化能力强</span></p>
            <p class="adv_det"><span>大模型能学更多特征模式，新任务新数据泛化能力强，无需额外训练。</span></p>
          </div>
          <div class="advantage_msg">
            <img src="~@/assets/imgs/diting/adv_03.png" alt="">
            <p class="adv_title"><span>学习能力强</span></p>
            <p class="adv_det"><span>大模型可以共享知识，能够使用之前学习到的知识来解决新任务。</span></p>
          </div>
          <div class="advantage_msg">
            <img src="~@/assets/imgs/diting/adv_04.png" alt="">
            <p class="adv_title"><span>计算成本低</span></p>
            <p class="adv_det"><span>大模型计算资源需求少，适用于计算资源有限的应用场景。</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-grey">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>产品功能</p>
          <p>PRODUCT FUNCTION</p>
        </div>
        <div class="hy_cpgn_body">
          <div class="fun_msg">
            <img src="~@/assets/imgs/diting/fun_01.png" alt="">
            <div>
              <p class="fun_title"><span>大模型API调用</span></p>
              <p class="fun_det"><span>访问和利用该模型的能力。API调用是一种编程接口，允许用户通过编写代码来使用大模型的功能，如语言生成、问答、文本分类等。</span></p>
            </div>
          </div>
          <div class="fun_msg">
            <img src="~@/assets/imgs/diting/fun_02.png" alt="">
            <div>
              <p class="fun_title"><span>大模型微调服务</span></p>
              <p class="fun_det"><span>通过在已训练好的模型上进行训练来提高模型在特定任务上的性能。</span></p>
            </div>
          </div>
          <div class="fun_msg">
            <img src="~@/assets/imgs/diting/fun_03.png" alt="">
            <div>
              <p class="fun_title"><span>嵌入调用服务</span></p>
              <p class="fun_det"><span>一种表示文本、语音、图像等数据的方法，可以将数据转换为向量形式，方便进行机器学习建模。</span></p>
            </div>
          </div>
          <div class="fun_msg">
            <img src="~@/assets/imgs/diting/fun_04.png" alt="">
            <div>
              <p class="fun_title"><span>行业大模型调用服务</span></p>
              <p class="fun_det"><span>可以根据不同的行业（如金融、医疗、教育等）提供不同的API调用和嵌入调用服务，以满足特定行业的需求。</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hy_index_bg">
      <div class="hy_index_cpyss content-container">
        <div class="hy_index_title page-title">
          <p>产品演示</p>
          <p>PRODUCT DEMONSTRATION</p>
        </div>
        <div class="hy_cpys_body">
          <div class="video_box_diting">
            <video :src="videoSrc" controls preload :poster="posterSrc"></video>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>项目案例</p>
          <p>PROJECT CASES</p>
        </div>
        <div class="hy_xmal_body">
          <div class="swiper-container swiper" id="swiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide s-slide-1">
                <div class="case_name">
                  <p><span>教育</span></p>
                  <p><span>帮助实现与教育产业的深度融合</span></p>
                </div>
                <div class="case_details_hide">
                  <p><span>海河·谛听智慧课堂助手是一款基于海河·谛听技术开发的智能语音教学助手。它可以帮助教师实现语音转文字、语音指令控制、智能识别学生发言等功能，让教学更加便捷高效。同时，海河·谛听智慧课堂助手还可以为学生提供语音练习、智能测评等服务，为他们的学习提供更多的便利。</span></p>
                </div>
              </div>
              <div class="swiper-slide s-slide-2">
                <div class="case_name">
                  <p><span>办公</span></p>
                  <p><span>帮助实现与办公产业的深度融合</span></p>
                </div>
                <div class="case_details_hide">
                  <p><span>海河·谛听智能会议助手是一款基于海河·谛听技术开发的智能语音会议助手。它可以帮助与会者实现语音转文字、翻译、智能识别发言等功能，让会议更加高效有序。同时，海河·谛听智能会议助手还可以为与会者提供会议纪要、智能语音记录等服务，方便他们更好地记录和整理会议内容。</span></p>
                </div>
              </div>
              <div class="swiper-slide s-slide-3">
                <div class="case_name">
                  <p><span>金融</span></p>
                  <p><span>帮助实现与金融产业的深度融合</span></p>
                </div>
                <div class="case_details_hide">
                  <p><span>海河·谛听智能客服助手是一款基于海河·谛听技术开发的智能语音客服助手。它可以帮助客服人员实现语音转文字、翻译、智能识别客户需求等功能，让客服更加高效准确。同时，海河·谛听智能客服助手还可以为客服人员提供智能回复、客户数据分析等服务，方便他们更好地为客户服务。</span></p>
                </div>
              </div>
              <div class="swiper-slide s-slide-4">
                <div class="case_name">
                  <p><span>医疗</span></p>
                  <p><span>帮助实现与医疗产业的深度融合</span></p>
                </div>
                <div class="case_details_hide">
                  <p><span>海河·谛听智能诊断助手是一款基于海河·谛听技术开发的智能语音诊断助手。它可以帮助医生实现语音转文字、翻译、智能识别病情等功能，让诊断更加准确高效。同时，海河·谛听智能诊断助手还可以为医生提供医学知识库、病例数据分析等服务，方便他们更好地诊断病情。</span></p>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-prev" id="swiper_prev2"></div>
          <div class="swiper-button-next" id="swiper_next2"></div>
        </div>
      </div>
    </div>
    <div class="page-container-white">
      <div class="page-title-container content-container">
        <div class="page-title">
          <p>合作伙伴</p>
          <p>COOPERATIVE PARTNER</p>
        </div>
        <div class="hzhb-body">
          <div><img src="~@/assets/imgs/par_11.jpg" alt=""></div> <!--滴滴-->
          <div><img src="~@/assets/imgs/par_12.jpg" alt=""></div> <!--阿里巴巴-->
          <div><img src="~@/assets/imgs/par_13.jpg" alt=""></div> <!--阿里云-->
          <div><img src="~@/assets/imgs/par_14.jpg" alt=""></div> <!--天大-->
          <div><img src="~@/assets/imgs/par_15.jpg" alt=""></div> <!--软银-->

          <div><img src="~@/assets/imgs/par_21.jpg" alt=""></div> <!--株式会社-->
          <div><img src="~@/assets/imgs/par_22.jpg" alt=""></div> <!--恒银金融-->
          <div><img src="~@/assets/imgs/par_23.jpg" alt=""></div> <!--三甲-->
          <div><img src="~@/assets/imgs/par_24.jpg" alt=""></div> <!--云译-->
          <div><img src="~@/assets/imgs/par_25.jpg" alt=""></div> <!--中汽-->

          <div><img src="~@/assets/imgs/par_31.jpg" alt=""></div> <!--塔米-->
          <div><img src="~@/assets/imgs/par_32.jpg" alt=""></div> <!--INN-->
          <div><img src="~@/assets/imgs/par_33.jpg" alt=""></div> <!--津云-->
          <div><img src="~@/assets/imgs/par_34.jpg" alt=""></div> <!--烽火-->
          <div><img src="~@/assets/imgs/par_35.jpg" alt=""></div> <!--天津广电-->
        </div>
      </div>
    </div>
    <div class="page-container-grey">
      <div class="page-hzzx">
        <p><span>点击下方按钮，获得我们的专属支持</span></p>
        <div><button><a href="mailto:bd@huiyan-tech.com" target="_blank">合作咨询</a></button></div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper";
import Banner from './Banner.vue'
import Video from '../assets/imgs/diting/diting.mp4'
import Poster from '../assets/imgs/diting/bg_qp.jpg'
export default {
  name: 'diting',
  data () {
    return {
      videoSrc: Video,
      posterSrc: Poster
    }
  },
  components: {
    Banner
  },
  mounted () {
    this.$nextTick(() => {
      new Swiper("#swiper2", {
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 4,
        // loop: true,
        // loopFillGroupWithBlank: true,
        navigation: {
          nextEl: "#swiper_prev2",
          prevEl: "#swiper_prev2",
        }
      });
    })
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
@import '../assets/css/product.css';
.hy_index_bg {
  height: 1000px;
}
.hy_cpys_body{
  position: relative;
  width: 100%;
  height: 789px;
  background: url("~@/assets/imgs/diting/dem_main.png") no-repeat center center;
  background-size: 100% 100%;
}

/*产品演示*/
.video_pic_box{
  position: absolute;
  top: 60px;
  bottom: 15px;
  right: 16px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  width: 256px;
}

.s-slide-1 {
  background: url('~@/assets/imgs/diting/case_img1.png') no-repeat center center;
  background-size: 100% 100%;
}
.s-slide-2 {
  background: url('~@/assets/imgs/diting/case_img2.png') no-repeat center center;
  background-size: 100% 100%;
}
.s-slide-3 {
  background: url('~@/assets/imgs/diting/case_img3.png') no-repeat center center;
  background-size: 100% 100%;
}
.s-slide-4 {
  background: url('~@/assets/imgs/diting/case_img4.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>